
import Vue from "vue";
import { HTTP } from "@/utils/http-common";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "index",
  data(): any {
    return {
      password: null,
      error: null,
      passwordConfirmed: false,
      qrCode: null,
      mfaCode: null,
      recoveryCodes: null,
      mfaActivated: false,
    };
  },
  computed: {
    ...mapGetters(["userRole", "twoFactor"]),
  },
  methods: {
    confirmPassword() {
      this.error = null;
      HTTP.post("user/confirm-password", {
        password: this.password,
      })
        .then(() => {
          this.passwordConfirmed = true;
          this.enable2FA();
        })
        .catch((error) => {
          if (error.response?.data?.password) {
            this.error = error.response.data.password[0];
          } else {
            this.error =
              "Wystąpił błąd, spróbuj ponownie za chwilę lub skontaktuj się z nami.";
          }
        });
    },
    enable2FA() {
      HTTP.post("user/two-factor-authentication").then(() => {
        this.getQRCode();
      });
    },
    getQRCode() {
      HTTP.get("user/two-factor-qr-code").then((response) => {
        this.qrCode = response.data.svg;
      });
    },
    getRecoveryKeys() {
      this.error = null;
      this.recoveryCodes = null;
      HTTP.get("user/two-factor-recovery-codes")
        .then((response) => {
          this.recoveryCodes = response.data;
        })
        .catch(() => {
          this.error =
            "Wystąpił błąd, spróbuj ponownie za chwilę lub skontaktuj się z nami.";
        });
    },
    confirm2FA() {
      this.error = null;
      HTTP.post("user/confirmed-two-factor-authentication", {
        code: this.mfaCode,
      })
        .then(() => {
          this.getRecoveryKeys();
          this.mfaActivated = true;
        })
        .catch((error) => {
          if (error.response?.data?.code) {
            this.error = error.response?.data?.code[0];
          } else {
            this.error =
              "Wystąpił błąd, spróbuj ponownie za chwilę lub skontaktuj się z nami.";
          }
        });
    },
  },
});
